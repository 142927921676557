<template>
  <div v-if="$route.query.room && !roomError">
    <div v-if="!stream" class="error-msg">
      <a href="https://discord.gg/secretid" target="_blank" class="logo">
        <img src="@/assets/logo.png" alt="">
      </a>
      KAMERA ERİŞİM İZNİ VERİN
    </div>
    <div v-else>
      <div class="home" v-if="!end">
        <a href="https://discord.gg/secretid" target="_blank" class="logo">
          <img src="@/assets/logo.png" alt="">
        </a>
        <video ref="remoteVideo" autoplay class="remote-video"></video>
        <video ref="localVideo" autoplay class="local-video"></video>
        <div></div>
        <div class="buttons">
          <button @click="toggleCamera" :class="{ close: !camera }">
            <ion-icon name="camera-outline"></ion-icon>
          </button>
          <button @click="toggleMicrophone" :class="{ close: !microphone }">
            <ion-icon name="mic-outline"></ion-icon>
          </button>
          <button @click="endCall" class="end-call">
            <ion-icon name="call-outline"></ion-icon>
          </button>
        </div>
      </div>
      <div v-else class="error-msg">
        <a href="https://discord.gg/secretid" target="_blank" class="logo">
          <img src="@/assets/logo.png" alt="">
        </a>
        ARAMA SONLANDIRILDI
      </div>
    </div>
  </div>
  <div v-else class="error-msg">
    <a href="https://discord.gg/secretid" target="_blank" class="logo">
      <img src="@/assets/logo.png" alt="">
    </a>
    GEÇERSİZ ODA
  </div>
</template>

<script>
const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

import { Peer } from 'peerjs';
import axios from 'axios';

export default {
  name: 'HomeView',
  components: {},
  data() {
    return {
      end: false,
      peer: null,
      room: null,
      call: null,
      conn: null,
      stream: null,
      roomError: false,
      camera: false,
      microphone: true,
      connected: false,
    }
  },
  async mounted() {
    let Script = document.createElement("script");
    Script.setAttribute(
      "src",
      "https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.esm.js"
    );
    Script.setAttribute("type", "module");
    document.head.appendChild(Script);

    this.room = this.$route.query.room;

    if (this.room) {
      navigator.getUserMedia = (navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia);

      navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(stream => {
        this.stream = stream;
        this.$nextTick(() => {
          this.$refs.localVideo.srcObject = stream;
          this.$refs.localVideo.volume = 0;
          this.stream.getVideoTracks()[0].enabled = this.camera;
          this.peer = new Peer();
          console.log(this.stream);

          this.peer.on('open', this.peerOpen);
          this.peer.on("connection", this.peerConnection);
          this.peer.on("call", this.peerCall);
        });
      }).catch(() => {
        this.stream = undefined;
      });


    }
  },
  methods: {
    toggleCamera() {
      this.camera = !this.camera;
      this.stream.getVideoTracks()[0].enabled = this.camera;
    },
    toggleMicrophone() {
      this.microphone = !this.microphone;
      this.stream.getAudioTracks()[0].enabled = this.microphone;
    },
    async endCall() {
      await this.closeCall();
      this.conn.send('endCall');
    },
    async closeCall() {
      this.end = true;
      this.$refs.remoteVideo.srcObject = null;
      await axios({
        url: 'https://api.secretid.com.tr/end-room',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          roomId: this.room,
        }
      })
    },
    async peerOpen() {
      console.log(this.peer.id);

      let connect = await axios({
        url: 'https://api.secretid.com.tr/join-room',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          roomId: this.room,
          userId: this.peer.id
        }
      });

      console.log(connect.data);

      if (connect.data == 'FULL' || connect.data == 'ERROR') {
        this.roomError = true;
        return;
      }

      this.connected = true;

      if (connect.data == 'OK') return;

      this.conn = this.peer.connect(connect.data);
      console.log(this.conn);
      this.conn.on("data", this.connData);
      this.conn.on("close", this.connClose);

      this.call = this.peer.call(connect.data, this.stream);
      console.log(this.call, this.conn);
      this.call.on('stream', this.callStream);
    },
    peerConnection(conn) {
      console.log("connection");
      this.conn = conn;
      console.log(this.conn);
      this.conn.on("data", this.connData);
      this.conn.on("close", this.connClose);
    },
    async callStream(stream) {
      this.$refs.remoteVideo.srcObject = stream;
      this.$refs.remoteVideo.volume = 1;
      this.$refs.remoteVideo.play();
      await sleep(500);
      this.$refs.remoteVideo.pause();
      await sleep(500);
      this.$refs.remoteVideo.play();
    },
    connData(data) {
      if (data == 'endCall') {
        this.closeCall();
      }
    },
    async peerCall(call) {
      this.call = call;
      this.call.answer(this.stream);
      this.call.on('stream', this.callStream);
    },
    async connClose() {
      await this.closeCall();
    }
  },
}
</script>

<style lang="scss">
* {
  font-family: 'Poppins', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  background: #202225;
}

#app {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home {
  background: #2f3136;
  height: calc(100vh - 50px);
  width: calc(100vw - 50px);
  border-radius: 10px;
  position: relative;
  overflow: hidden;

  .logo {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 9;

    img {
      height: 60px;
    }
  }

  .local-video {
    width: 15%;
    top: 20px;
    right: 20px;
    border-radius: 10px;
    position: absolute;
  }

  .remote-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .buttons {
    position: absolute;
    bottom: 40px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .4;
    transition: .25s all;

    &:hover {
      opacity: 1;
    }

    button {
      border: none;
      width: 60px;
      height: 60px;
      font-size: 24px;
      border-radius: 50px;
      margin: 0 5px;
      background-color: #5a64ef;
      color: #fff;
      transition: .25s all;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        transform: translateY(-10px);
      }

      &:active {
        transform: translateY(0px);
      }

      &.end-call {
        background-color: #db0000;
      }

      &.close {
        background-color: #202225;
      }
    }
  }
}

.error-msg {
  color: #fff;
  font-size: 24px;
  text-align: center;

  .logo {
    display: block;
    text-align: center;
    margin-bottom: 20px;

    img {
      height: 60px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .home {
    width: 100vw;
    height: 100vh;
    border-radius: 0;

    .local-video {
      width: 35%;
    }

    .logo {
      img {
        height: 40px;
      }
    }
  }
}
</style>